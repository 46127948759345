import React, { Component } from "react";
import Navbar from "../components/Navbar";
import kart from "../img/kart.jpg";
import logoStor from "../img/logo-stor.png";
import pin from "../img/pin_Artboard 1.svg";
import vipps from "../img/vipps.png";
import { PlacePoint } from "../lib/functions";
import { url } from "../index";
import axios from "axios";
import {
  Header,
  Form,
  Preview,
  Pagination,
  Kart,
  Footer,
  Error,
} from "../lib/elements/Pay";

class Pay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phoneOrigin: "",
      phoneChanged: "",
      melding: "",
      stolper: [],
      position: 0,
      focused: null,
      loading: true,
    };
  }

  async componentDidMount() {
    const result = await axios.get(url("getStolpe"));
    const res = result.data;
    document.title = "OIF | Kjøp stolpe";
    this.setState({
      stolper: res
        .filter((stolpe) => stolpe.Eier === "Ingen")
        .map((s) => s.Stolpe),
      loading: false,
    });
  }

  render() {
    const updateName = (event) => this.setState({ name: event.target.value });
    const updateEmail = (event) => this.setState({ email: event.target.value });
    const updatePhone = (event) => {
      if (isNaN(event.target.value)) return;

      let checkPhone = event.target.value.match(/^(\d{3})(\d{2})(\d{3})$/);

      this.setState({
        phoneOrigin: event.target.value,
        phoneChanged:
          checkPhone === null
            ? event.target.value
            : `${checkPhone[1]} ${checkPhone[2]} ${checkPhone[3]}`,
      });
    };
    const updateMelding = (event) =>
      this.setState({ melding: event.target.value });

    const { stolper } = this.state;
    const payId = Number(window.location.pathname.split("/").pop());
    const currentPosition = [];
    stolper.map((s, i) => {
      if (s === payId) {
        currentPosition.push(i);
      }
      return null;
    });
    const current = currentPosition[0];

    return (
      <div>
        <Navbar type="pay" />
        {this.state.loading ? null : current === undefined ? (
          <Error stolpe={payId} />
        ) : (
          <>
            <Header />
            <div className="pay-main">
              <Form
                payId={payId}
                name={this.state.name}
                email={this.state.email}
                phoneOrigin={this.state.phoneOrigin}
                melding={this.state.melding}
                updateName={updateName}
                updateEmail={updateEmail}
                updatePhone={updatePhone}
                updateMelding={updateMelding}
                pay={this}
              />
              <Preview
                pin={pin}
                payId={payId}
                name={this.state.name}
                email={this.state.email}
                phoneOrigin={this.state.phoneOrigin}
                phoneChanged={this.state.phoneChanged}
                melding={this.state.melding}
                vipps={vipps}
                pay={this}
              />
            </div>
            <Pagination stolper={stolper} current={current} />
            <Kart kart={kart} payId={payId} PlacePoint={PlacePoint} />
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Footer logo={logoStor} />
        </div>
      </div>
    );
  }
}

export default Pay;
