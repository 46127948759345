import React from "react";

const StolpeStats = (props) => {
  const { stolper } = props;

  return (
    <ul className="stolpe-stats">
      {[
        {
          statement:
            stolper.filter((s) => s.Eier === "Ingen").length === 1
              ? "Ledig stolpe"
              : "Ledige stolper",
          number: stolper.filter((s) => s.Eier === "Ingen").length,
        },
        {
          statement: `Kjøpt stolpe${
            stolper.filter((s) => s.Eier !== "Ingen").length === 1 ? "" : "r"
          }`,
          number: stolper.filter((s) => s.Eier !== "Ingen").length,
        },
      ].map((x, i) => {
        return (
          <li key={i}>
            <p className="stats-1">{x.number}</p>
            <p className="stats-2">{x.statement}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default StolpeStats;
