import React from "react";

const Footer = (props) => {
  const { logo } = props;

  return (
    <footer>
      <ul>
        <img src={logo} alt="oif" />
        <li>&copy; 2022 Orkanger IF. All rights reserved.</li>
      </ul>
      <ul>
        <li>
          <a href="/terms">Terms</a>
        </li>
        <li className="biztek-credit">
          <a href="https://biztek.no">
            <p>
              Powered by <b>Biztek</b>
            </p>
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
