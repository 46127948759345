import React, { Component, createRef } from "react";
import axios from "axios";
import { url } from "../index.js";
import Navbar from "../components/Navbar";
import Modal from "@mui/material/Modal";
import logoStor from "../img/logo-stor.png";
import kart from "../img/kart.jpg";
import { getStolpe, PlacePoint } from "../lib/functions";
import {
  StolpeModus,
  Stolper,
  Header,
  LedigeStolper,
  Footer,
  StolpeStats,
} from "../lib/elements/Stolper";

class Home extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.state = {
      open: false,
      scrolled: false,
      loading: true,
      pick: null,
      modus: 0,
      stolper: [],
      width: 0,
      height: 0,
    };
  }

  async componentDidMount() {
    const result = await axios.get(url("getStolpe"));
    const res = result.data;
    document.title = "OIF Stolper";
    this.setState({ stolper: res, loading: false });
  }

  render() {
    const { open, scrolled, loading, pick, modus, stolper } = this.state;

    const handleOpen = (s) => {
      this.setState({ open: true, pick: s });
    };
    const handleClose = () => this.setState({ open: false, pick: null });
    const setMode = (i) => this.setState({ modus: i });

    window.onscroll = (e) => {
      if (e.path[1].scrollY > 0) this.setState({ scrolled: true });
      else this.setState({ scrolled: false });
    };

    return (
      <div>
        <div className="main">
          <Navbar scrolled={scrolled} />
          <Header />
        </div>
        <div
          id="stolper"
          style={{
            marginTop: "3vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          ref={this.ref}
        >
          <div className="padding">
            <LedigeStolper loading={loading} stolper={stolper} />
            <StolpeModus modus={modus} setMode={setMode} />
            <Stolper
              loading={loading}
              stolper={stolper}
              modus={modus}
              handleOpen={handleOpen}
              Modal={Modal}
              open={open}
              handleClose={handleClose}
              kart={kart}
              getStolpe={getStolpe}
              pick={pick}
              PlacePoint={PlacePoint}
              checkRef={this.ref?.current?.offsetWidth}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StolpeStats stolper={stolper} />
          <Footer logo={logoStor} />
        </div>
      </div>
    );
  }
}

export default Home;
