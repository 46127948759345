import React from "react";

const Header = (props) => {
  return (
    <div className="header">
      <h1>Vil du ha din egen stolpe?</h1>
      <h3>Her kan du kjøpe navnet ditt på en stolpe i ulvåsen</h3>
      <button onClick={() => (window.location.href = "#stolper")}>
        Se stolper
      </button>
    </div>
  );
};

export default Header;
