import React from "react";

const StolpeModus = (props) => {
  const { modus, setMode } = props;

  return (
    <ul className="stolpe-modus">
      {["Alle", "Ledige", "Kjøpte"].map((x, i) => {
        return (
          <li
            key={i}
            onClick={() => setMode(i)}
            className={modus === i ? "active" : ""}
          >
            {x}
          </li>
        );
      })}
    </ul>
  );
};

export default StolpeModus;
