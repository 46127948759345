import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Pages/Home";
import Pay from "./Pages/Pay";
import Terms from "./Pages/Terms";
import React from "react";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="pay" element={<Pay />}>
          <Route path=":payId" element={<Pay />} />
        </Route>
        <Route path="terms" element={<Terms />} />
      </Routes>
    </Router>
  );
};

export default App;
