import React from "react";

const Error = (props) => {
  const { stolpe } = props;

  return (
    <div className="pay-error">
      <div className="error-container">
        <h1>Det har skjedd en feil</h1>
        <hr />
        <p>
          Stolpe <b>#{stolpe}</b> er enten opptatt eller ikke en av stolpene.
        </p>
      </div>
    </div>
  );
};

export default Error;
