import React from "react";

const Header = () => {
  return (
    <div className="pay-header">
      <h1>Betal for din helt egne stolpe i Ulvåsen</h1>
      <h3>Få navnet ditt på en stolpe i ulvåsen - bli en del av løypa!</h3>
      <a href="#kart">
        <button>Se plassering</button>
      </a>
    </div>
  );
};

export default Header;
