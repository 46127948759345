import React from "react";

const Kart = (props) => {
  const { kart, payId, PlacePoint } = props;

  return (
    <div id="kart" className="pay-map">
      <div className="container">
        <img src={kart} alt="kart" />
        <PlacePoint pay={true} number={payId} type="point" />
      </div>
    </div>
  );
};

export default Kart;
