import mapPin from "../../img/pin_Artboard 1.svg";
import React from "react";

const PlacePoint = (props) => {
  const { number, type, pay } = props;
  const points = [
    { left: 360, bottom: 130 },
    { left: 365, bottom: 120 },
    { left: 361, bottom: 110 },
    { left: 355, bottom: 100 },
    { left: 363, bottom: 91 },
    { left: 373, bottom: 93 },
    { left: 383, bottom: 96 },
    { left: 393, bottom: 100 },
    { left: 403, bottom: 104 },
    { left: 413, bottom: 105 },
    { left: 423, bottom: 107 },
    { left: 433, bottom: 106 },
    { left: 443, bottom: 110 },
    { left: 453, bottom: 113 },
    { left: 463, bottom: 118 },
    { left: 473, bottom: 126 },
    { left: 477, bottom: 140 },
    { left: 470, bottom: 150 },
    { left: 455, bottom: 149 },
    { left: 445, bottom: 145 },
    { left: 432, bottom: 141 },
    { left: 412, bottom: 135 },
    { left: 393, bottom: 131 },
    { left: 373, bottom: 129 },
    { left: 343, bottom: 132 },
    { left: 326, bottom: 135 },
    { left: 337, bottom: 147 },
    { left: 355, bottom: 150 },
    { left: 370, bottom: 154 },
    { left: 379, bottom: 169 },
    { left: 373, bottom: 187 },
    { left: 369, bottom: 207 },
    { left: 366, bottom: 227 },
    { left: 365, bottom: 247 },
    { left: 361, bottom: 267 },
    { left: 359, bottom: 287 },
    { left: 370, bottom: 299 },
    { left: 390, bottom: 309 },
    { left: 410, bottom: 312 },
    { left: 430, bottom: 315 },
    { left: 450, bottom: 320 },
    { left: 470, bottom: 327 },
    { left: 490, bottom: 339 },
    { left: 510, bottom: 353 },
    { left: 530, bottom: 373 },
    { left: 550, bottom: 399 },
    { left: 570, bottom: 412 },
    { left: 583, bottom: 432 },
    { left: 578, bottom: 452 },
    { left: 558, bottom: 459 },
    { left: 530, bottom: 464 },
    { left: 505, bottom: 467 },
    { left: 480, bottom: 455 },
    { left: 455, bottom: 438 },
    { left: 430, bottom: 420 },
    { left: 405, bottom: 410 },
    { left: 380, bottom: 395 },
    { left: 355, bottom: 384 },
    { left: 330, bottom: 375 },
    { left: 305, bottom: 374 },
    { left: 288, bottom: 390 },
    { left: 300, bottom: 408 },
    { left: 315, bottom: 417 },
    { left: 330, bottom: 425 },
    { left: 345, bottom: 432 },
    { left: 365, bottom: 442 },
    { left: 380, bottom: 452 },
    { left: 398, bottom: 465 },
    { left: 415, bottom: 473 },
    { left: 430, bottom: 485 },
    { left: 440, bottom: 491 },
    { left: 450, bottom: 493 },
    { left: 460, bottom: 492 },
    { left: 466, bottom: 480 },
    { left: 476, bottom: 475 },
    { left: 486, bottom: 472 },
    { left: 496, bottom: 470 },
    { left: 505, bottom: 468 },
  ];

  const currentPoint = points.filter((x, i) => i === number),
    current = currentPoint[0];

  let state =
    current.bottom < 247
      ? "starten"
      : current.bottom >= 247 && current.bottom < 373
      ? "midten"
      : "enden";

  const padding = pay ? "5px" : "4px";

  if (type === "point")
    return currentPoint.map((point) => {
      return (
        <img
          key="img"
          src={mapPin}
          alt="map"
          style={{
            paddingBottom: padding,
            width: "5px",
            background: 0,
            height: "5px",
            position: "absolute",
            left: `${point.left}px`,
            bottom: `${point.bottom}px`,
            zIndex: 5,
            transform: "scale(8)",
            animation: "pinAnimation .8s ease 0s 1 normal forwards",
          }}
        />
      );
    });

  return state;
};

export default PlacePoint;
