import React from "react";

const LedigeStolper = (props) => {
  const { loading, stolper } = props;
  return (
    <>
      <h3 style={{ opacity: ".6", fontSize: "15px", fontWeight: "0" }}>
        Se Stolper
      </h3>
      <h2 style={{ marginBottom: "3vh" }}>
        {loading ? 0 : `${stolper.filter((s) => s.Eier === "Ingen").length}`}{" "}
        ledige stolper
      </h2>
    </>
  );
};

export default LedigeStolper;
