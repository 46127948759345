const getStolpe = (number) => {
  const importImages = (img) => {
    return img.keys().map(img);
  };

  const images = importImages(
    require.context("../../img/Bilder stolper/", false, /\.(png|jpe?g|svg)$/)
  );

  const imagesArray = images.map((img) => {
    const string = img.split(/[/.]+/)[3];
    return string;
  });

  const imageArray = imagesArray.sort((a, b) => {
    return a + b;
  });

  const returnImage = images.filter((img) =>
    img.includes(imageArray[number])
  )[0];

  return returnImage;
};

export default getStolpe;
