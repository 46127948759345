import React from "react";

const Pagination = (props) => {
  const { stolper, current } = props;

  return (
    <div className="pagination">
      <ul>
        <li
          className={stolper[current - 1] === undefined ? "disabled" : ""}
          onClick={() =>
            stolper[current - 1] !== undefined
              ? (window.location.href = `/pay/${stolper[current - 1]}`)
              : null
          }
        >
          <i className="fas fa-arrow-left-long"></i>
          <p className="text">Forrige stolpe</p>
          <p className="number">{stolper[current - 1]}</p>
        </li>
        <li
          className={stolper[current + 1] === undefined ? "disabled" : ""}
          onClick={() =>
            stolper[current + 1] !== undefined
              ? (window.location.href = `/pay/${stolper[current + 1]}`)
              : null
          }
        >
          <p className="number">{stolper[current + 1]}</p>
          <p className="text">Neste stolpe</p>
          <i className="fas fa-arrow-right-long"></i>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
