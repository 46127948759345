import React from "react";

const Stolper = (props) => {
  const {
    loading,
    stolper,
    modus,
    handleOpen,
    Modal,
    open,
    handleClose,
    kart,
    getStolpe,
    pick,
    PlacePoint,
    checkRef,
  } = props;

  return (
    <div className="stolper">
      {loading
        ? null
        : stolper
            .filter((s) =>
              modus === 0
                ? s
                : modus === 1
                ? s.Eier === "Ingen"
                : s.Eier !== "Ingen"
            )
            .map((s) => {
              return (
                <>
                  <div
                    className={`stolpe${s.Eier === "Ingen" ? "" : " kjøpt"}`}
                    key={s.Stolpe}
                    onClick={() =>
                      s.Eier === "Ingen"
                        ? checkRef && checkRef > 1000
                          ? handleOpen(s)
                          : (window.location.href = `/pay/${s.Stolpe}`)
                        : null
                    }
                  >
                    <p className="stolpe-nummer">{s.Stolpe}</p>
                    {s.Eier === "Ingen" ? (
                      <>
                        <button>Oversikt</button>
                        <p className="stolpe-pris">{s.Pris},-</p>
                      </>
                    ) : (
                      <p className="stolpe-eid">
                        Eid av <b>{s.Eier}</b>
                      </p>
                    )}
                  </div>
                </>
              );
            })}
      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
      >
        <ul className="modal-opened">
          <div className="kart">
            <img src={kart} alt="kart" />
          </div>
          <li key="first" className="first">
            <img src={getStolpe(pick?.Stolpe)} alt="stolpe" />
          </li>
          <li key="second" className="second">
            <div className="container">
              <h1>Stolpe {pick?.Stolpe || 1}</h1>
              <hr />
              <ul>
                <li key="eier">
                  {pick?.Eier === "Ingen"
                    ? "Ingen eier"
                    : `Eid av ${pick?.Eier}`}
                </li>
                <li key="pris">kr {pick?.Pris || 500},-</li>
                <li key="plassering">
                  Plassert i <PlacePoint number={pick?.Stolpe} type="state" />{" "}
                  av løypa
                </li>
              </ul>
              <button
                onClick={() => (window.location.href = `/pay/${pick?.Stolpe}`)}
              >
                Kjøp stolpe {pick?.Stolpe}
              </button>
            </div>
          </li>
          <PlacePoint number={pick?.Stolpe} type="point" />
        </ul>
      </Modal>
    </div>
  );
};

export default Stolper;
