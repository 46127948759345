import React from "react";
import { url } from "../../../index";

const handlePurchase = (name, email, number, message, item) => {
  const randomNum = Math.floor(10000000 + Math.random() * 90000000);

  if (!name.length || !email.length || !number.length) {
    const messageArray = [];
    if (!name.length) messageArray.push("navn");
    if (!email.length) messageArray.push("email");
    if (!number.length) messageArray.push("nummer");
    return alert(`NB! ${messageArray.join(", ")} kan ikke være tom.`);
  }

  window.location.href = url(
    `vipps/${name}/${email}/${number}/${
      !message.length ? "Ingen melding" : message
    }/${randomNum}-${item}`
  );
};

const Preview = (props) => {
  const {
    pin,
    payId,
    name,
    email,
    phoneOrigin,
    phoneChanged,
    melding,
    vipps,
    pay,
  } = props;
  return (
    <div className="preview">
      <div>
        <div>
          <img src={pin} alt="pin" />
          <p>
            Stolpe nummer <b>{payId}</b>
          </p>
        </div>
        <hr />
      </div>
      <div className="words">
        <p className={pay.state.focused === 0 ? "focused" : ""}>
          <i className="fas fa-user" />
          {name ? name : "Ingen Navn"}
        </p>
        <p className={pay.state.focused === 1 ? "focused" : ""}>
          <i className="fas fa-envelope" />
          {email ? email : "Ingen Email"}
        </p>
        <p className={pay.state.focused === 2 ? "focused" : ""}>
          <i className="fas fa-phone" />
          {phoneOrigin[0] ? "+47 " : ""}
          {phoneChanged ? phoneChanged : "Ingen Nummer"}
        </p>
        <p className={pay.state.focused === 3 ? "focused" : ""}>
          {melding ? melding : "Ingen Melding"}
        </p>
      </div>
      <h2 style={{ textAlign: "center", marginTop: "-15px" }}>500,-</h2>
      <div className="btn-div-center">
        <div
          className="pay-kjop"
          onClick={() =>
            handlePurchase(name, email, phoneOrigin, melding, payId)
          }
        >
          <button>Betal med Vipps</button>
          <img className="vipps" src={vipps} alt="vipps" />
        </div>
      </div>
    </div>
  );
};

export default Preview;
