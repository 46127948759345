import React from "react";

const Form = (props) => {
  const {
    name,
    email,
    phoneOrigin,
    melding,
    updateName,
    updateEmail,
    updatePhone,
    updateMelding,
    pay,
    payId,
  } = props;

  const onFocus = (e) => {
    let number,
      name = e.target.name;

    if (name === "navn") number = 0;
    else if (name === "email") number = 1;
    else if (name === "tlf") number = 2;
    else if (name === "melding") number = 3;

    pay.setState({ focused: number });
  };

  const onBlur = (e) => {
    pay.setState({ focused: null });
  };

  const text = [`[#0001${payId}] ${name}`, `${email}`, `${melding}`];
  const max = 100;
  const enter = 2 * text.length;
  const whitespace = 4 + enter;
  const leftText =
    max -
    name.length -
    email.length -
    melding.length -
    payId.toString().length -
    whitespace;
  const leftSize =
    max - name.length - email.length - payId.toString().length - whitespace;

  return (
    <form>
      <div>
        <h1>Kontakt informasjon</h1>
        <ul>
          <li>
            <p>For- og Etternavn</p>
            <input
              value={name}
              onChange={(event) => updateName(event)}
              onFocus={onFocus}
              onBlur={onBlur}
              type="text"
              id="navn"
              name="navn"
              placeholder="Hva er navnet ditt?"
              required
            />
          </li>
          <li>
            <p>Mail Adresse</p>
            <input
              value={email}
              onChange={(event) => updateEmail(event)}
              onFocus={onFocus}
              onBlur={onBlur}
              type="email"
              id="email"
              name="email"
              placeholder="Hva er mailen din?"
              required
            />
          </li>
          <li>
            <p>Telefonnummer</p>
            <input
              value={phoneOrigin}
              onChange={(event) => updatePhone(event)}
              onFocus={onFocus}
              onBlur={onBlur}
              type="tel"
              id="tlf"
              name="tlf"
              minLength="8"
              maxLength="8"
              placeholder="Hva er nummeret ditt?"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              required
            />
          </li>
          <li>
            <p>Melding</p>
            <div className="text">
              <textarea
                value={melding}
                onChange={(event) => updateMelding(event)}
                onFocus={onFocus}
                onBlur={onBlur}
                maxLength={leftSize}
                type="text"
                id="melding"
                name="melding"
                placeholder="Vil du sende med en melding?"
                required
              />
              <p style={{ fontWeight: 600, opacity: 0.6 }}>{leftText}</p>
            </div>
          </li>
        </ul>
      </div>
    </form>
  );
};

export default Form;
