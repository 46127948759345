import logoStor from "../img/logo-stor.png";
import React from "react";

const Navbar = (props) => {
  const { scrolled, type } = props;
  return (
    <nav
      className={`pay-nav${scrolled ? " scrolled" : ""}${
        type === "pay" ? " nav-pay" : ""
      }`}
    >
      <a href="/">
        <img src={logoStor} alt="oif" />
        <p>Orkanger IF</p>
      </a>
      <ul className="pay">
        <li onClick={() => (window.location.href = "https://orkanger-if.no")}>
          <div className="hover-link">
            <p>
              Gå tilbake til <b>orkanger-if.no</b>
            </p>
            <div className="i">
              <i className="fas fa-arrow-left"></i>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
